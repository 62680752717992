import { BalHeading, BalText } from "@baloise/ds-react";
import {
    DeepPartial,
    Form,
    FormProps,
    FormValues,
} from "@baloise-cfa/form-renderer-frontend";
import {
    InsurancePackageType,
    OptionalGuarantee,
    OptionalGuaranteeType,
} from "@baloise-cfa/tsclient/mob";
import { useMediaQuery } from "@lg-cfa/hooks";
import { MediaQuery } from "@lg-cfa/interfaces-enums";
import classnames from "classnames";
import { graphql, navigate } from "gatsby";
import React, { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";

import {
    DiscountBlock,
    Footer,
    Loading,
    Navigation,
    Summary,
} from "@modules/shared/components";
import { CarType, PageNames } from "@modules/shared/enums";
import { getNavigationPages } from "@modules/shared/helpers";
import { useInsurance } from "@modules/shared/hooks";
import { AppState, appState } from "@modules/shared/state";
import { InsurancePriceOption, PageProps } from "@modules/shared/types";
import {
    createInsuranceFields,
    InsuranceModal,
    SimulatorLayout,
} from "@modules/simulator";

const InsuranceChoicePage: React.FC<PageProps> = ({ pageContext }) => {
    const { foundPage: page, ownPageObjects: allPages, language } = pageContext;
    const [appData, setAppData] = useRecoilState<AppState>(appState);
    const { t } = useTranslation();
    const isMobile = useMediaQuery(MediaQuery.Mobile);
    const formRef = useRef<FormProps<FormValues>>();
    const [showModal, setShowModal] = useState<boolean>(false);
    const { prevPage, nextPage } = getNavigationPages(allPages, page);
    const { insurances, extraOptions, bonusMalus, loading } = useInsurance(
        language,
        appData?.InsuranceChoice?.insurance,
        allPages,
    );
    const [desiredInsuranceType, setDesiredInsuranceType] = useState<
        InsurancePackageType | undefined
    >(undefined);

    const insuranceFields = useMemo(
        () => createInsuranceFields(insurances, extraOptions),
        [insurances, extraOptions],
    );

    const initialFormValues: DeepPartial<FormValues> = useMemo(() => {
        return {
            ...appData,
            InsuranceChoice: appData.InsuranceChoice,
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        appData.InsuranceChoice?.extraOptions,
        appData.InsuranceChoice?.insurance,
    ]);

    const updateFormState = (formValues: FormValues): void => {
        const { InsuranceChoice } = formValues;

        const baPackage = insurances.find(
            (insurancePackage) =>
                insurancePackage.type ===
                InsurancePackageType.ThirdPartyLiability,
        );

        const newExtraOptions = extraOptions.reduce(
            (acc: OptionalGuarantee[], option: InsurancePriceOption) => {
                if (InsuranceChoice.extraOptions?.includes(option.value)) {
                    acc.push({
                        price: option.price,
                        type: option.value,
                    });
                }
                return acc;
            },
            [],
        );

        setAppData({
            ...appData,
            InsuranceChoice: {
                ...formValues.InsuranceChoice,
                extraOptionsResult: newExtraOptions,
                insurance: InsuranceChoice.insurance,
                priceBA: baPackage?.price,
            },
        });
    };

    const handleSubmit = (values: FormValues): void => {
        updateFormState(values);

        if (nextPage) {
            navigate(nextPage.paths[language]);
        }
    };

    const handleOnChange = (values: FormValues): void => {
        if (
            values.InsuranceChoice.extraOptions?.includes(
                OptionalGuaranteeType.SafetyPackOmnium,
            ) &&
            values.InsuranceChoice.insurance?.type &&
            values.InsuranceChoice.insurance?.type !==
                InsurancePackageType.FullOmnium
        ) {
            updateFormState(values);
            setShowModal(true);
            setDesiredInsuranceType(values.InsuranceChoice.insurance.type);
        } else {
            updateFormState(values);
        }
    };

    const handleModalSubmit = (values: FormValues): void => {
        const foundInsurance = insurances.find(
            (insurance) => insurance.type === values.chosenInsurance,
        );

        if (foundInsurance) {
            let extraOptions = appData.InsuranceChoice?.extraOptions || [];
            if (foundInsurance.type !== InsurancePackageType.FullOmnium) {
                extraOptions = extraOptions.filter(
                    (option) =>
                        option !== OptionalGuaranteeType.SafetyPackOmnium,
                );
            }
            setAppData({
                ...appData,
                InsuranceChoice: {
                    ...appData.InsuranceChoice,
                    insurance: foundInsurance,
                    extraOptions: extraOptions,
                },
            });
            setShowModal(false);
        }
    };

    return (
        <>
            {showModal && (
                <InsuranceModal
                    onClose={() => setShowModal(false)}
                    onSubmit={handleModalSubmit}
                    desiredInsuranceType={desiredInsuranceType}
                />
            )}
            {loading && <Loading translationKey="loading.tariffs.text" />}
            <SimulatorLayout
                title={t("simulator.insurancechoice.title")}
                page={page}
                allPages={allPages}
                language={language}
            >
                <main className="container my-xx-large">
                    <div className="container is-compact insurance-choice-title text-align-center">
                        <BalHeading level="h1" subtitle>
                            {t("simulator.insurancechoice.title")}
                        </BalHeading>
                        <BalText>
                            {t("simulator.insurancechoice.intro")}
                        </BalText>
                    </div>
                    <Form
                        ref={formRef}
                        scrollToFieldError
                        formContext={{
                            t,
                            nameSpace: PageNames.Insurance,
                        }}
                        initialValues={initialFormValues}
                        onSubmit={handleSubmit}
                        onChange={handleOnChange}
                        fields={insuranceFields}
                        showErrorMessageCard={false}
                        enableReinitialize
                    />
                </main>
                <DiscountBlock
                    driversLicenseDate={appData?.Driver?.license}
                    hasFormula36={!!appData.InsuranceChoice?.insurance?.formula}
                    carRegistrationDate={appData.CarUsage?.registrationdate}
                    carType={appData.BeforeWeStart?.cartype as CarType}
                    bonusMalus={bonusMalus}
                    mileage={appData.CarUsage?.mileage}
                    amountOfSafetyOptions={
                        appData.CarDescription?.safetyOptions?.length ?? 0
                    }
                />
                {extraOptions && (
                    <Summary
                        chosenInsurance={appData.InsuranceChoice?.insurance}
                        chosenOptions={
                            appData.InsuranceChoice?.extraOptionsResult
                        }
                    >
                        <div
                            className={classnames(
                                "flex",
                                {
                                    "justify-content-end": !isMobile,
                                },
                                {
                                    "flex-direction-column-reverse": isMobile,
                                },
                            )}
                        >
                            <Navigation
                                t={t}
                                language={language}
                                prevPage={prevPage}
                                nextPage={nextPage}
                                prevPageBtnText="quote.insuranceChoiceModal.title"
                                prevColor="secondary"
                                prevIcon="edit"
                                nextColor="primary"
                                inverted
                                onNextClick={() =>
                                    formRef.current?.handleSubmit()
                                }
                            />
                        </div>
                        <Footer isDark isCompact />
                    </Summary>
                )}
            </SimulatorLayout>
        </>
    );
};

export default InsuranceChoicePage;

export const pageQuery = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
